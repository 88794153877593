import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'


export const domEL = {
    header: document.getElementById('js-header'),
    headerScroll: document.getElementById('js-header-scroll'),
    previewList: document.getElementById('preview-list'),
    description: document.getElementById('js-description-feedback'),
    imprint: document.getElementById('js-imprint-feedback'),
    filter: document.getElementById('js-filter-feedback'),
    title: document.getElementById('js-title-feedback'),
    hints: document.getElementById('js-hints-feedback'),
    score: document.getElementById('js-score-feedback')
}

export const hasClass = function(el, className) {
	if (el.classList) return el.classList.contains(className);
	else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};

export const addClass = function(el, className) {
	var classList = className.split(' ');
 	if (el.classList) el.classList.add(classList[0]);
 	else if (!Helper.hasClass(el, classList[0])) el.className += " " + classList[0];
 	if (classList.length > 1) Helper.addClass(el, classList.slice(1).join(' '));
};

export const removeClass = function(el, className) {
	var classList = className.split(' ');
	if (el.classList) el.classList.remove(classList[0]);	
	else if(Helper.hasClass(el, classList[0])) {
		var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
		el.className=el.className.replace(reg, ' ');
	}
	if (classList.length > 1) Helper.removeClass(el, classList.slice(1).join(' '));
};
export const toggleClass = function(el, className, bool) {
	if(bool) addClass(el, className);
	else removeClass(el, className);
  };
  
export const setAttributes = function(el, attrs) {
	for(var key in attrs) {
	  el.setAttribute(key, attrs[key]);
	}
};

export const extend = function() {
	var extended = {};
	var deep = false;
	var i = 0;
	var length = arguments.length;
  
	if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
	  deep = arguments[0];
	  i++;
	}
  
	var merge = function (obj) {
	  for ( var prop in obj ) {
		if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
		  if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
			extended[prop] = extend( true, extended[prop], obj[prop] );
		  } else {
			extended[prop] = obj[prop];
		  }
		}
	  }
	};
  
	for ( ; i < length; i++ ) {
	  var obj = arguments[i];
	  merge(obj);
	}
  
	return extended;
};
  

/*
=====================================
    GSAP ScrollTrigger
=====================================
*/


gsap.registerPlugin(ScrollTrigger);