/*
=====================================
    Sketch: 3D CANVAS 
=====================================
*/
import {addClass, removeClass}  from './helper.js';

import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm//lights/RectAreaLightUniformsLib.js';

import { GUI } from 'dat.gui'


export class Sketch {
    constructor(){
        // this.dom = dom;
        
        this.setupGlobals();
        this.initScene();
        // this.initEvents();
        // removeClass(this.el.body, 'loading');
        // this.animateTexte();
        
    }
    setupGlobals(){
        this.scene;
        this.camera;
        this.renderer;
        this.controls;

        this.ambient;
        this.light;

        this.gui;
        
        this.params;
        this.ambientTop;

        this.lights = {};
        this.lightParam = {
            top: {
                x: 0,
                y: 8,
                z: 0,
                width: 10,
                height: 10,
                intensity: 0.25
            },
            key: {
                x: -8,
                y: 8,
                z: 10,
                width: 5,
                height: 5,
                intensity: 0.25
            },
            fill: {
                x: 9,
                y: 8,
                z: 5,
                width: 10,
                height: 10,
                intensity: 0.25
            }
        };

        this.model;
    }

    initEvents(){
        
        if(this.buttons.length > 0){
            for (let i = 0; i < this.buttons.length; i++) {
                // this.buttons[i].addEventListener('click', this.changeModel.bind(this));
            }
        }
    }

    initScene(){

        this.clock = new THREE.Clock();
        this.scene = new THREE.Scene();
        
        this.camera = new THREE.PerspectiveCamera( 10, window.innerWidth / window.innerHeight, 1, 1000 );
        // this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 1000 );
        this.camera.position.set( 0.2, 0.4, 3.1 );
        // this.camera.position.set( 0.0, 0.0, 5 );
    
        this.camera.lookAt(0,0,0);

        RectAreaLightUniformsLib.init();

        // Area Light 1
        this.lights.topParent = new THREE.Object3D();
        this.lights.top = new THREE.RectAreaLight(0xffffff, this.lightParam.top.intensity,  this.lightParam.top.width, this.lightParam.top.height );
        this.lights.top.visible = true;

        this.lights.top.position.set(this.lightParam.top.x,this.lightParam.top.y,this.lightParam.top.z);
        this.lights.top.lookAt(0,0,0);

        this.lights.topHelper = new RectAreaLightHelper(this.lights.top);
        this.lights.topHelper.visible = false;
        this.lights.top.add(this.lights.topHelper);

        this.scene.add(this.lights.topParent);
        this.lights.topParent.add(this.lights.top);


        // Area Light 2
        this.lights.keyParent = new THREE.Object3D();
        this.lights.key = new THREE.RectAreaLight(0xffffff, this.lightParam.key.intensity,  this.lightParam.key.width, this.lightParam.key.height );
        this.lights.key.visible = true;

        this.lights.key.position.set(this.lightParam.key.x,this.lightParam.key.y,this.lightParam.key.z);
        this.lights.key.lookAt(0,0,0);

        this.lights.keyHelper = new RectAreaLightHelper(this.lights.key);
        this.lights.keyHelper.visible = false;
        this.lights.key.add(this.lights.keyHelper);

        this.scene.add(this.lights.keyParent);
        this.lights.keyParent.add(this.lights.key);

        // Area Light 3
        this.lights.fillParent = new THREE.Object3D();
        this.lights.fill = new THREE.RectAreaLight(0xffffff, this.lightParam.fill.intensity,  this.lightParam.fill.width, this.lightParam.fill.height );
        this.lights.fill.visible = true;

        this.lights.fill.position.set(this.lightParam.fill.x,this.lightParam.fill.y,this.lightParam.fill.z);
        this.lights.fill.lookAt(0,0,0);

        this.lights.fillHelper = new RectAreaLightHelper(this.lights.fill);
        this.lights.fillHelper.visible = false;
        this.lights.fill.add(this.lights.fillHelper);

        this.scene.add(this.lights.fillParent);
        this.lights.fillParent.add(this.lights.fill);

        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.renderer.setClearColor( 0xe2e2e2, 1 );
        // this.renderer.outputEncoding = THREE.sRGBEncoding;
        

        this.renderer.outputEncoding = THREE.sRGBEncoding;
        document.getElementById('zwilling-canvas').appendChild( this.renderer.domElement );

        this.controls = new OrbitControls( this.camera, this.renderer.domElement );
        // this.controls.enableZoom = false;

        this.loadGui();
        
        window.addEventListener( 'resize', this.resize.bind(this), false);

        let url = new URL("../../src/media/models/AnimFullMachine_Black_005_draco.glb", import.meta.url );
        url = "" + url;
        this.loadMesh(url);

    }

    loadGui(){
        this.params = {
            ambientTop: { 
                positionX: 0,
                positionY: 8,
                positionZ: 0,
                width: 10,
                height: 10,
                intensity: 0.25,
                helper: false,
                enable: true
            },
            ambientKey: {
                positionX: -8,
                positionY: 8,
                positionZ: 10,
                width: 5,
                height: 5,
                intensity: 0.25,
                helper: false,
                enable: true
            },
            ambientfill: {
                positionX: 8,
                positionY: 8,
                positionZ: 5,
                width: 10,
                height: 10,
                intensity: 0.25,
                helper: false,
                enable: true
            }

        }
        this.gui = new GUI();
        this.ambientTop = this.gui.addFolder('Top');
        this.ambientTop.open();
        this.ambientTop.add(this.params.ambientTop, 'enable').onChange(value => { this.lights.top.visible = value });
        this.ambientTop.add(this.params.ambientTop, 'width').min(1).max(15).onChange( value => { 
            this.lights.top.width = value;
        });
        this.ambientTop.add(this.params.ambientTop, 'height').min(1).max(15).onChange( value => { 
            this.lights.top.height = value;
        });
        this.ambientTop.add(this.params.ambientTop, 'positionX').min(-15).max(15).onChange( value => { 
            this.lights.top.position.x = value;
        });
        this.ambientTop.add(this.params.ambientTop, 'positionY').min(-15).max(15).onChange( value => { 
            this.lights.top.position.y = value;
        });
        this.ambientTop.add(this.params.ambientTop, 'positionZ').min(-15).max(15).onChange( value => { 
            this.lights.top.position.z = value;
        });
        this.ambientTop.add(this.params.ambientTop, 'intensity').min(0).max(1).onChange( value => { 
            this.lights.top.intensity = value;
        });
        this.ambientTop.add(this.params.ambientTop, 'helper').onChange(value => { this.lights.topHelper.visible = value });

        this.ambientKey = this.gui.addFolder('Key');
        this.ambientKey.open();
        this.ambientKey.add(this.params.ambientKey, 'enable').onChange(value => { this.lights.key.visible = value });
        this.ambientKey.add(this.params.ambientKey, 'width').min(1).max(15).onChange( value => { 
            this.lights.key.width = value;
        });
        this.ambientKey.add(this.params.ambientKey, 'height').min(1).max(15).onChange( value => { 
            this.lights.key.height = value;
        });
        this.ambientKey.add(this.params.ambientKey, 'positionX').min(-15).max(15).onChange( value => { 
            this.lights.key.position.x = value;
        });
        this.ambientKey.add(this.params.ambientKey, 'positionY').min(-15).max(15).onChange( value => { 
            this.lights.key.position.y = value;
        });
        this.ambientKey.add(this.params.ambientKey, 'positionZ').min(-15).max(15).onChange( value => { 
            this.lights.key.position.z = value;
        });
        this.ambientKey.add(this.params.ambientKey, 'intensity').min(0).max(1).onChange( value => { 
            this.lights.key.intensity = value;
        });
        this.ambientKey.add(this.params.ambientKey, 'helper').onChange(value => { this.lights.keyHelper.visible = value });

        this.ambientfill = this.gui.addFolder('fill');
        this.ambientfill.open();
        this.ambientfill.add(this.params.ambientfill, 'enable').onChange(value => { this.lights.fill.visible = value });
        this.ambientfill.add(this.params.ambientfill, 'width').min(1).max(15).onChange( value => { 
            this.lights.fill.width = value;
        });
        this.ambientfill.add(this.params.ambientfill, 'height').min(1).max(15).onChange( value => { 
            this.lights.fill.height = value;
        });
        this.ambientfill.add(this.params.ambientfill, 'positionX').min(0).max(15).onChange( value => { 
            this.lights.fill.position.x = value;
        });
        this.ambientfill.add(this.params.ambientfill, 'positionY').min(0).max(15).onChange( value => { 
            this.lights.fill.position.y = value;
        });
        this.ambientfill.add(this.params.ambientfill, 'positionZ').min(0).max(15).onChange( value => { 
            this.lights.fill.position.z = value;
        });
        this.ambientfill.add(this.params.ambientfill, 'intensity').min(0).max(1).onChange( value => { 
            this.lights.fill.intensity = value;
        });
        this.ambientfill.add(this.params.ambientfill, 'helper').onChange(value => { this.lights.fillHelper.visible = value });

    }
    loadMesh(url){
        var that = this;
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
        dracoLoader.preload();
        loader.setDRACOLoader( dracoLoader );
        // console.log("url:  " + url);
        
        loader.load(
            // resource URL
            url,
            function ( gltf ) {
                console.log('GLTF MODEL is loaded 🚨');
                that.model = gltf.scene;
                that.model.position.set( 0, -0.15, 0 );
                that.scene.add( that.model );
                that.model.children[0].visible = false;
                removeClass(document.body, 'loading');
                that.updateScene();
            },
            // called while loading is progressing
            function ( xhr ) {},
            // called when loading has errors
            function ( error ) { console.log( error );}
        );
    }
    createAnimation(){
        var that = this;

        // gsap.registerPlugin(ScrollTrigger);
                 
        let proxy = {
            get time() {
                return this.mixer.time;
            },
            set time(value) {
                this.animations[0].paused = false;
                this.mixer.setTime(value);
                this.animations[0].paused = true;
            }
        };


    }
    updateScene(){

        requestAnimationFrame(() => this.updateScene());

        const delta = this.clock.getDelta();
        const sin = Math.sin(this.clock.getElapsedTime())*5;

        
        this.controls.update();
        this.renderer.clear();
        this.renderer.render( this.scene, this.camera );


        
    }
    resize(){
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( window.innerWidth, window.innerHeight );
    }


}