/*
=====================================
    APP 
=====================================
*/

import {Sketch}  from './sketch.js';
new Sketch();

// import {Content}  from './content.js';
// import {addClass, removeClass}  from './helper.js';


// import { gsap } from "gsap";

// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

// import {SeqA}  from './seqA.js';
// import {SeqB}  from './seqB.js';
// import {SeqC}  from './seqC.js';
// import {SeqD}  from './seqD.js';
// import {SeqE}  from './seqE.js';
// import {SeqF}  from './seqF.js';
// import {SeqG}  from './seqG.js';
// import {SeqH}  from './seqH.js';
// import {SeqI}  from './seqI.js';
// import {SeqK}  from './seqK.js';
// import {SeqJ}  from './seqJ.js';

// import {ImgSequences}  from './imgSequences.js';


// class App {
//     constructor(){
//         console.log('start app');
//         this.el = {
//             app: this,
//             content: null,
//             body: document.body,
//             pageContent: document.getElementById('pc-feed'),
//             locoScroll: null,
//             winsize: {width: 0, height: 0},
//             seqA: null,
//             seqB: null,
//             seqC: null,
//             seqD: null,
//             seqE: null,
//             seqF: null,
//             seqG: null,
//             seqH: null,
//             seqI: null,
//             seqJ: null,
//             seqk: null,
//             imgSequences: null,
//             scrolltrigger: ScrollTrigger,
//             loadImage: this.loadImage,
//             scrollToSeq: this.scrollToSeq
//         }

//         this.registerBlur();
//         this.calcWinsize();
//         this.initEvents();

//         this.el.imgSequences = new ImgSequences(this.el);
//         this.createDom();
//     }

//     initEvents(){
//         window.addEventListener('resize', this.calcWinsize.bind(this));
//     }

//     createDom(){
//         const userLang = document.documentElement.lang;
//         this.el.content = Content[userLang];
//         this.el.seqA =  new SeqA(this.el);
//         this.el.seqB =  new SeqB(this.el);
//         this.el.seqC =  new SeqC(this.el);
//         this.el.seqD =  new SeqD(this.el);
//         this.el.seqE =  new SeqE(this.el);
//         this.el.seqF =  new SeqF(this.el);
//         this.el.seqG =  new SeqG(this.el);
//         this.el.seqH =  new SeqH(this.el);
//         this.el.seqJ =  new SeqJ(this.el);
//         this.el.seqI =  new SeqI(this.el);
//         this.el.seqK =  new SeqK(this.el);
        
//     }

//     registerBlur(){
//       const blurProperty = gsap.utils.checkPrefix("filter"),
//         blurExp = /blur\((.+)?px\)/,
//         getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

//         gsap.registerPlugin({
//             name: "blur",
//             get(target) {
//                 return +(getBlurMatch(target)[1]) || 0;
//             },
//             init(target, endValue) {
//                 let data = this,
//               filter = gsap.getProperty(target, blurProperty),
//               endBlur = "blur(" + endValue + "px)",
//               match = getBlurMatch(target)[0],
//               index;
//           if (filter === "none") {
//             filter = "";
//           }
//           if (match) {
//             index = filter.indexOf(match);
//             endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
//           } else {
//             endValue = filter + endBlur;
//             filter += filter ? " blur(0px)" : "blur(0px)";
//           }
//           data.target = target; 
//           data.interp = gsap.utils.interpolate(filter, endValue); 
//             },
//             render(progress, data) {
//                 data.target.style[blurProperty] = data.interp(progress);
//             }
//         });
//     }

//     loadImage(image){
//         return new Promise(resolve => {
//             // Set image source
//             image.src = image.src;

//             const imageLoad = () => {
//                 // The Image is Loaded
//                 image.isLoaded = true;
//                 // Remove the Listener
//                 image.removeEventListener('load', imageLoad);
//                 // Promise resolve
//                 resolve(image);
//             }
//             image.addEventListener('load', imageLoad);
//         });
//     }
//     scaleValue(value, r1, r2) {
//         return ( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ];
//     }

//     calcWinsize(){
//         this.el.winsize = {width: window.innerWidth, height: window.innerHeight};
//     }
//     scrollToSeq(target, offset){
//         const rec = target.getBoundingClientRect();
//         const windowScroll = document.documentElement.scrollTop;
//         window.scrollTo(0, rec.top + windowScroll + offset);
//     }
// }

// console.log('start file');

// setTimeout(() => {

//     window.scrollTo({
//         top: 0,
//         left: 0,
//         behavior: 'instant'
//     });
    
//     document.body.style.overflow = 'hidden';
//     document.documentElement.setAttribute('data-theme', 'light');
    
//     document.getElementById('password-request-feedback').innerHTML = '';
//     removeClass(document.body, 'loading');

//     const password = document.getElementById('password-request');
//     const localLocation = localStorage.getItem('pass');

//     if(localLocation == 'zwillingprototype'){
//         addClass(document.body, 'loading');
//         document.getElementById('password-overlay').style.display = 'none';
//         setTimeout(() => {
//             new App();    
//         }, 500);
//     } else {
//         password.addEventListener('submit', function(event){
//             event.preventDefault();
//             if(event.target[0].value === 'zwillingprototype'){
//                 addClass(document.body, 'loading');
//                 document.getElementById('password-overlay').style.display = 'none';
//                 localStorage.setItem('pass', 'zwillingprototype');
//                 setTimeout(() => {
//                     new App();    
//                 }, 500);
//             } else {
//                 document.getElementById('password-request-feedback').innerHTML = 'falsches Passwort'
//             }
//         });
//     }
// }, 500);
